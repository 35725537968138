import React, { useState, useEffect } from 'react';
import { Exam } from '../../../../common/model/Exam';
import { useDispatch } from 'react-redux';
import { ValidateForm, ValidateInput } from '../../../../../utils/customHooks/validateForm';
import * as action from '../services/ExamActions';
import ExamRules from './examRules'
interface IAddExam {
    closeModal: Function,
    mode: string
}

const initialState: Exam = {
    title: '',
    type: '',
    price: 0,
    status: 0,
    user: '',
}

const AddExam = (props: IAddExam) => {
    const [exam, setExam] = useState(initialState)
    const [error, setError] = useState(initialState)

    useEffect(() => {
        setExam({
            ...exam,
            type: 'private'
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])

    const dispatch = useDispatch();
    const inputHandler = (e: any) => {
        setError({
            ...error,
            [e.target.name]: ''
        })
        setExam({
            ...exam,
            [e.target.name]: e.target.value
        });
    }

    const confirmAction = async () => {
        let errorMsgs: any = ValidateForm(exam, ExamRules);
        setError({ ...errorMsgs });
        let validated = Object.values(errorMsgs).join('').length === 0;
        if (validated) {
            await dispatch(action.addExam(exam));
            props.closeModal();
        }
    }

    const inputValidation = (e: any) => {
        let errorMsg = ValidateInput(e.target.name, e.target.value, ExamRules);
        setError({
            ...error,
            [e.target.name]: errorMsg
        });
    }


    return (
        <div className="edit-client dialog-content">
            <div className="dialog-content-area flex">
                <div className="form-area my-md full-width">
                    <div className="form-group my-md full-width">
                        <label>Title</label>
                        <input
                            name="title"
                            type="text"
                            onChange={inputHandler}
                            onBlur={inputValidation} />
                        {error.title !== '' ? <span className="error-text">{error.title}</span> : ''}
                    </div>
                    <div className="form-group my-md full-width">
                        <label>Description</label>
                        <input
                            name="description"
                            type="text"
                            onChange={inputHandler}
                            onBlur={inputValidation} />
                        {error.description !== '' ? <span className="error-text">{error.description}</span> : ''}
                    </div>
                    <div className="form-group my-md full-width">
                        <label>Language</label>
                        <select name="type" value={exam.language} onChange={inputHandler} onBlur={inputValidation}>
                            {languages.map(language => <option value={`${language.code}`}>{language.language}</option>)}
                        </select>
                        {error.type !== '' ? <span className="error-text">{error.language}</span> : ''}
                    </div>
                    <div className="form-group my-md full-width">
                        <label>Exam Type</label>
                        <select name="type" value={exam.type} onChange={inputHandler} onBlur={inputValidation}>
                            <option value="easy">Easy</option>
                            <option value="medium">Medium</option>
                            <option value="advance">Advance</option>
                        </select>
                        {error.type !== '' ? <span className="error-text">{error.type}</span> : ''}
                    </div>
                </div>
            </div>
            <div className="button-area flex justify-end mtyar-lg">
                <button className="btn primary" onClick={confirmAction}>Add Exam</button>
                <button className="btn primary outlined ml-xs" onClick={() => props.closeModal()}>Cancel</button>
            </div>
        </div>
    )
}

export default AddExam


const languages = [
    { code: 'af', language: 'Afrikaans' },
    { code: 'ar', language: 'Arabic' },
    { code: 'be', language: 'Belarusian' },
    { code: 'bg', language: 'Bulgarian' },
    { code: 'bn', language: 'Bengali' },
    { code: 'ca', language: 'Catalan' },
    { code: 'cs', language: 'Czech' },
    { code: 'cy', language: 'Welsh' },
    { code: 'da', language: 'Danish' },
    { code: 'de', language: 'German' },
    { code: 'el', language: 'Greek' },
    { code: 'en', language: 'English' },
    { code: 'eo', language: 'Esperanto' },
    { code: 'es', language: 'Spanish' },
    { code: 'et', language: 'Estonian' },
    { code: 'fa', language: 'Persian' },
    { code: 'fi', language: 'Finnish' },
    { code: 'fr', language: 'French' },
    { code: 'ga', language: 'Irish' },
    { code: 'gl', language: 'Galician' },
    { code: 'gu', language: 'Gujarati' },
    { code: 'he', language: 'Hebrew' },
    { code: 'hi', language: 'Hindi' },
    { code: 'hr', language: 'Croatian' },
    { code: 'ht', language: 'Haitian' },
    { code: 'hu', language: 'Hungarian' },
    { code: 'id', language: 'Indonesian' },
    { code: 'is', language: 'Icelandic' },
    { code: 'it', language: 'Italian' },
    { code: 'ja', language: 'Japanese' },
    { code: 'ka', language: 'Georgian' },
    { code: 'kn', language: 'Kannada' },
    { code: 'ko', language: 'Korean' },
    { code: 'lt', language: 'Lithuanian' },
    { code: 'lv', language: 'Latvian' },
    { code: 'mk', language: 'Macedonian' },
    { code: 'mr', language: 'Marathi' },
    { code: 'ms', language: 'Malay' },
    { code: 'mt', language: 'Maltese' },
    { code: 'nl', language: 'Dutch' },
    { code: 'no', language: 'Norwegian' },
    { code: 'pl', language: 'Polish' },
    { code: 'pt', language: 'Portuguese' },
    { code: 'ro', language: 'Romanian' },
    { code: 'ru', language: 'Russian' },
    { code: 'sk', language: 'Slovak' },
    { code: 'sl', language: 'Slovenian' },
    { code: 'sq', language: 'Albanian' },
    { code: 'sv', language: 'Swedish' },
    { code: 'sw', language: 'Swahili' },
    { code: 'ta', language: 'Tamil' },
    { code: 'te', language: 'Telugu' },
    { code: 'th', language: 'Thai' },
    { code: 'tl', language: 'Tagalog' },
    { code: 'tr', language: 'Turkish' },
    { code: 'uk', language: 'Ukrainian' },
    { code: 'ur', language: 'Urdu' },
    { code: 'vi', language: 'Vietnamese' },
    { code: 'zh', language: 'Chinese' },
]