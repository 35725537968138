import { setToasterState } from '../../../../../store/action';
import store from '../../../../../store/store';
import {
  GetRequest,
  PostRequest,
  PutRequest,
} from '../../../../../plugins/axios';
import { Question } from '../../../../common/model/Question';
import optionType from '../../../../common/enum/optionType';
import {
  AddData,
  GetCollectioninSortWhereData,
  SetData,
} from '../../../../../plugins/firebasefirestore';
import { deleteFile, uploadFile } from '../../../../../plugins/firebasestorage';
import questionType from '../../../../common/enum/questiontype';

const questionUrl = 'Questions';
const listeningUrl = process.env.REACT_APP_API_BASE_URL + 'listenings';
export const getQuestionsAdminSuccess = (payload: any) => {
  return {
    type: 'GET_QUESTIONS',
    payload: payload,
  };
};

export const setQuestionAddLoading = (payload: boolean) => {
  return {
    type: 'QUESTION_ADD_LOADING',
    payload: payload,
  };
};

export const getListenigAdminSuccess = (payload: any) => {
  return {
    type: 'GET_LISTENING',
    payload: payload,
  };
};

export const getQuestionsFromExamAdmin = (examId: any) => (dispatch: any) => {
  const userID = store.getState().authReducer.user.uid;
  if (userID) {
    GetCollectioninSortWhereData(
      questionUrl,
      'questionNumber',
      'exam',
      '==',
      examId
    )
      .then((querySnapshot) => {
        const res = querySnapshot.docs.map((doc) => doc.data());
        dispatch(getQuestionsAdminSuccess(res));
      })
      .catch((err) => {
        console.log(err);

        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Questions',
          })
        );
      });
  }
};

export const getExamListening = (examId: string) => (dispatch: any) => {
  const userID = store.getState().authReducer.user._id;
  if (userID) {
    GetRequest(`${listeningUrl}/${examId}/${userID}`, {}, {})
      .then((res) => {
        dispatch(getListenigAdminSuccess(res.data));
      })
      .catch((err) => {
        console.log(err);

        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Questions',
          })
        );
      });
  }
};

export const addExamListening = (payload: any) => (dispatch: any) => {
  const userID = store.getState().authReducer.user._id;
  const audioFile = payload.audio_file;
  const formData = new FormData();
  if (audioFile && userID) {
    formData.append('audio_file', audioFile);
    formData.append('exam', payload.exam);
    PostRequest(`${listeningUrl}/create/${userID}`, formData, {})
      .then((res) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Success',
            message: 'Successfully added Listening',
          })
        );
        dispatch(getExamListening(payload.exam));
      })
      .catch((err) => {
        console.log(err);

        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Error Adding',
            message: 'Error adding question',
          })
        );
      });
  } else {
    dispatch(
      setToasterState({
        appear: true,
        title: 'error',
        name: 'Incomplete Data',
        message: 'File or User Not Found!',
      })
    );
  }
};
export const editExamListening = (listeningId: string, payload: any) => (
  dispatch: any
) => {
  const userID = store.getState().authReducer.user._id;
  const audioFile = payload.audio_file;
  const formData = new FormData();
  if (audioFile && userID) {
    formData.append('audio_file', audioFile);
    formData.append('exam', payload.exam);
    PutRequest(`${listeningUrl}/${listeningId}/${userID}`, formData, {})
      .then((res) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Success',
            message: 'Successfully added Listening',
          })
        );
        dispatch(getExamListening(payload.exam));
      })
      .catch((err) => {
        console.log(err);

        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Error Adding',
            message: 'Error adding question',
          })
        );
      });
  } else {
    dispatch(
      setToasterState({
        appear: true,
        title: 'error',
        name: 'Incomplete Data',
        message: 'File or User Not Found!',
      })
    );
  }
};

export const addQuestionAdmin = (
  examId: string,
  payload: Question = {},
  imagePayload: any = {}
) => async (dispatch: any) => {
  const userID = store.getState().authReducer.user.uid;
  dispatch(setQuestionAddLoading(true));
  if (payload.mainHeadingImage) {
    const mainheadingImage = await uploadFile(
      examId,
      imagePayload.mainHeading
    );
    if (mainheadingImage) {
      payload = {
        ...payload,
        mainHeading: mainheadingImage,
      };
    }
  }

  if (payload.descriptionImage) {
    const descImage = await uploadFile(examId, imagePayload.description);
    if (descImage) {
      payload = {
        ...payload,
        description: descImage,
      };
    }
  }

  if (payload.optionType === optionType.IMAGE) {
    const option1Image = await uploadFile(examId, imagePayload.option1);
    const option2Image = await uploadFile(examId, imagePayload.option2);
    const option3Image = await uploadFile(examId, imagePayload.option3);
    const option4Image = await uploadFile(examId, imagePayload.option4);
    payload = {
      ...payload,
      option1: option1Image,
      option2: option2Image,
      option3: option3Image,
      option4: option4Image,
    };
  } else if (payload.optionType === optionType.LISTENING) {
    payload = {
      ...payload,
      option1: 'listening',
      option2: 'listening',
      option3: 'listening',
      option4: 'listening',
    };
  }

  if (payload.type === questionType.LISTENING) {
    const listeningUrl = await uploadFile(examId, payload.listening);
    if (listeningUrl) {
      payload = {
        ...payload,
        listening: listeningUrl,
      };
    }
  }
  if (userID) {
    payload = {
      ...payload,
      user: userID,
      exam: examId,
    };
    AddData(questionUrl, payload)
      .then((res) => {
        dispatch(setQuestionAddLoading(false));
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Success',
            message: 'Successfully added Exam',
          })
        );
        dispatch(getQuestionsFromExamAdmin(examId));
      })
      .catch((err) => {
        dispatch(setQuestionAddLoading(false));
        console.log(err);
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Error Adding',
            message: 'Error adding question',
          })
        );
      });
  } else {
    dispatch(setQuestionAddLoading(false));
    dispatch(
      setToasterState({
        appear: true,
        title: 'error',
        name: 'User Not Found',
        message: 'Error adding question',
      })
    );
  }
};

export const editQuestionAdmin = (
  questionId: string = '',
  payload: Question = {},
  imagePayload: any = {}
) => async (dispatch: any) => {
  const userID = store.getState().authReducer.user.uid;
  dispatch(setQuestionAddLoading(true));
  if (userID) {
    if (payload.mainHeadingImage && imagePayload.mainHeading) {
      //delete file
      deleteFile(payload.mainHeading);
      //update with new
      const mainHeadingImage = await uploadFile(
        payload.exam ? payload.exam : '',
        imagePayload.option1
      );
      if (mainHeadingImage) {
        payload = {
          ...payload,
          mainHeading: mainHeadingImage,
        };
      }
    }

    if (payload.descriptionImage && imagePayload.description) {
      //delete file
      deleteFile(payload.description ? payload.description : '');
      //update with new
      const descriptionImage = await uploadFile(
        payload?.exam ? payload?.exam : '',
        imagePayload.description
      );
      if (descriptionImage) {
        payload = {
          ...payload,
          description: descriptionImage,
        };
      }
    }

    if (payload.optionType === optionType.IMAGE) {
      if (imagePayload.option1) {
        //delete file
        deleteFile(payload.option1 ? payload.option1 : '');
        //update with new
        const option1Image = await uploadFile(
          payload?.exam ? payload?.exam : '',
          imagePayload.option1
        );
        payload = {
          ...payload,
          option1: option1Image,
        };
      }
      if (imagePayload.option2) {
        //delete file
        deleteFile(payload.option2 ? payload.option2 : '');
        //update with new
        const option2Image = await uploadFile(
          payload?.exam ? payload?.exam : '',
          imagePayload.option2
        );
        payload = {
          ...payload,
          option2: option2Image,
        };
      }

      if (imagePayload.option3) {
        //delete file
        deleteFile(payload.option3 ? payload.option3 : '');
        //update with new
        const option3Image = await uploadFile(
          payload?.exam ? payload?.exam : '',
          imagePayload.option3
        );
        payload = {
          ...payload,
          option3: option3Image,
        };
      }

      if (imagePayload.option4) {
        //delete file
        deleteFile(payload.option4 ? payload.option4 : '');
        //update with new
        const option4Image = await uploadFile(
          payload?.exam ? payload?.exam : '',
          imagePayload.option4
        );
        payload = {
          ...payload,
          option4: option4Image,
        };
      }
    }

    SetData(questionUrl, questionId, payload)
      .then((res) => {
        dispatch(setQuestionAddLoading(false));
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Success',
            message: 'Question Updated Sucessfully',
          })
        );
        dispatch(getQuestionsFromExamAdmin(payload.exam));
      })
      .catch((err) => {
        dispatch(setQuestionAddLoading(false));
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Error Adding',
            message: 'Error adding question',
          })
        );
      });
  } else {
    dispatch(setQuestionAddLoading(false));
    dispatch(
      setToasterState({
        appear: true,
        title: 'error',
        name: 'User Not Found',
        message: 'Error adding question',
      })
    );
  }
};
