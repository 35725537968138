import { db } from '../firebaseconfig';
import firebase from 'firebase';

export const AddData = (path: string, payload: any) => {
  const ref = db.collection(path).doc();
  const newKey = ref.id;
  return db
    .collection(path)
    .doc(newKey)
    .set({
      ...payload,
      id: newKey,
      date: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

export const SetData = (path: string, docid: string, payload: any) => {
  return db
    .collection(path)
    .doc(docid)
    .set({
      ...payload,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

export const UpdateData = (path: string, docid: string, payload: any) => {
  return db.collection(path).doc(docid).update(payload);
};

export const GetCollectionData = (path: string) => {
  return db.collection(path).get();
};

export const GetCollectionDataSortLimitDesc = (
  path: string,
  sortParam: string,
  limit: number
) => {
  return db.collection(path).orderBy(sortParam, 'desc').limit(limit).get();
};

export const GetCollectioninSortData = (path: string, sortParam: string) => {
  return db.collection(path).orderBy(sortParam).get();
};

export const GetCollectionWhereData = (
  path: string,
  whereParam: string,
  whereExp: any,
  whereRes: any
) => {
  return db.collection(path).where(whereParam, whereExp, whereRes).get();
};

export const GetCollectioninSortWhereData = (
  path: string,
  sortParam: string,
  whereParam: string,
  whereExp: any,
  whereRes: string
) => {
  return db
    .collection(path)
    .where(whereParam, whereExp, whereRes)
    .orderBy(sortParam)
    .get();
};

export const GetDocumentData = (path: any, docid: string) => {
  return db.collection(path).doc(docid).get();
};
