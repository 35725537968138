import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../store/action';
import Modal from '../../../../common/modal';
import Confirmation from '../../../../common/modal/Confirmation';
import EditExam from './components/EditExam';
import AddExam from './components/AddExam';
import { useHistory } from "react-router-dom";
import { Exam } from '../../../common/model/Exam'
import ExamCard from '../../../../common/ExamCard';

const Exams = () => {
    const examsList = useSelector((state: any) => {
        return state.adminReducer.exams;
    });
    const history = useHistory();
    const user = useSelector((state: any) => state.authReducer.user);
    const dispatch = useDispatch();
    const [exams, setExams] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editExamObj, setEditExamObj] = useState({ _id: "" });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [modalMode, setModalMode] = useState('add');

    useEffect(() => {
        user.uid && loadExams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        user.uid && loadExams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        setExams(examsList);
    }, [examsList])

    const loadExams = async () => {
        await dispatch(actions.getAdminExams());
    }

    const deleteAction = (obj: any) => {
        setShowConfirmation(true);
        setEditExamObj(obj)
    }

    const deleteConfirmation = async () => {
        // await dispatch(actions.clientDeleteUser(editUserObj.id));
        setShowConfirmation(false);
    }

    const addAction = () => {
        setModalMode('add');
        setShowModal(true);
    }

    const editExam = async (payload: any) => {        
        await dispatch(actions.adminEditExam(editExamObj._id, payload));
    }

    const editAction = (obj: any) => {
        setModalMode('edit');
        setEditExamObj(obj);
        setShowModal(true);
    }
    const viewExam = (obj: Exam) => {
        history.push(`exams/exam/${obj.id}`)
    }


    return (
        <section className="wrapper issues full-height full-width flex column py-md">
            <header className={'flex justify-between items-center mb-md'}>
                <div className="title-sm bold text-primary">List of Exams</div>
                <button className="btn primary" onClick={addAction}>Add Exams</button>
            </header>
            {/* <ListTable
                columns={columns}
                rows={exams}
                editAction={editAction}
                viewAction={viewExam}
                deleteAction={deleteAction}
                paginate={10}
            /> */}

            <div className="flex pt-md wrap">
                {exams.map((e: any) => <ExamCard
                    exam={e}
                    title={e.title}
                    editAction={editAction}
                    viewAction={viewExam}
                    deleteAction={deleteAction}
                />)}
            </div>
            {
                modalMode === 'add' ?
                    <Modal show={showModal} title={'Add Exam'}
                        closeModal={() => setShowModal(false)}>
                        <AddExam closeModal={() => setShowModal(false)} mode={modalMode} />
                    </Modal> :
                    <Modal show={showModal} title={'Edit User'}
                        closeModal={() => setShowModal(false)}>
                        <EditExam
                            editObj={editExamObj}
                            closeModal={() => setShowModal(false)}
                            editExam={editExam}
                            mode={modalMode}
                        />
                    </Modal>
            }
            <Confirmation title="Confirm" content="Are you sure you want to delete this item?" show={showConfirmation}
                confirm={deleteConfirmation}
                closeModal={() => setShowConfirmation(false)} />
        </section>
    )
}

export default Exams;
