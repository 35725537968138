import AdminLayout from '../modules/admin';
import Login from '../modules/auth/Login/Login';
import Signup from '../modules/auth/Signup/Signup';

let routeList: {
  path: string;
  component: any;
  name: string;
  exact: boolean;
  isAuth: boolean;
}[];

routeList = [
  {
    path: '/login',
    name: 'login',
    exact: true,
    isAuth: false,
    component: Login,
  },
  {
    path: '/',
    name: 'login',
    exact: true,
    isAuth: false,
    component: Login,
  },
  {
    path: '/signup',
    name: 'signup',
    exact: true,
    isAuth: false,
    component: Signup,
  },
  {
    path: '/admin',
    name: 'admin',
    exact: false,
    isAuth: true,
    component: AdminLayout,
  },
];

export default routeList;
