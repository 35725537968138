import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../store/action';
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import ExamHeading from './components/ExamHeading';
import DisplayQuestionResult from './components/DisplayQuestionResult';
import ResultNumberLists from './components/ResultNumberList';
import { Question } from '../../../common/model/Question';

const PreviewResult = () => {
    const resultsList = useSelector((state: any) => state.adminReducer.results);
    const questionsList: Question[] = useSelector((state: any) => state.adminReducer.questions);
    const { resultId } = useParams<{ resultId: string }>();
    const [userAnswers, setUserAnswers] = useState<any>([])
    const [position, setPosition] = useState(0)
    const dispatch = useDispatch();
    const history = useHistory();

    const [examMode, setExamMode] = useState('all')

    useEffect(() => {
        // dispatch(actions.setQuestionPosition(position));
        const result = resultsList.find((r: any) => r._id === resultId)
        console.log(result);

        if (result) {
            loadQuestions(result.exam)
            setUserAnswers(result.answers)
        } else {
            returnBack();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultsList])

    const loadQuestions = async (examId: string) => {
        await dispatch(actions.getQuestionsFromExamAdmin(examId));
    }

    const returnBack = async () => {
        history.goBack()
    }
    const decreasePosition = async () => {
        position > 0 && setPosition(position - 1);
    }
    const increasePosition = async () => {
        position < userAnswers.length - 1 && setPosition(position + 1);
    }

    const goBack = () => {
        history.goBack();
    }
    const switchToAllMode = async () => {
        setExamMode("all");
        // await dispatch(actions.setListeningPlaying(false))
    };

    const switchToSingleMode = (position: number) => {
        setExamMode("single");
        changePosition(position);
    };

    const changePosition = (position: number) => {
        setPosition(position)
    };
    const increaseDescresePosition = (num: number) => {
        if (num === 1) {
            increasePosition()
        } else {
            decreasePosition()
        }
    };



    return (
        <section className="wrapper issues full-height full-width flex column py-md">
            <div className="my-sm">
                <button className="btn primary" onClick={goBack}>Back</button>
            </div>
            <ExamHeading totalClick={switchToAllMode} answers={userAnswers} />
            {examMode === "single" && userAnswers.length > 0 && (
                <DisplayQuestionResult
                    selectedAnswer={userAnswers[position].answer}
                    question={questionsList[position]}
                    changePosition={increaseDescresePosition}
                    playAudio={() => { }}
                    position={position}
                />
            )}
            <div className="flex column">
                <div className="flex row questions">
                    {examMode === "all" && (
                        <ResultNumberLists
                            answers={userAnswers}
                            setListeningStatus={() => { }}
                            changeMode={switchToSingleMode}
                        />
                    )}
                </div>
            </div>
        </section>
    )
}

export default PreviewResult;
