import RadioButton from '../../../../../common/Radio';
import { Question } from '../../../../common/model/Question';
import questionType from '../../../../common/enum/questiontype';
import optionType from '../../../../common/enum/optionType';
import ImageHelper from '../../../../common/component/ImageHelper';
interface IPreviewQuestionAdd {
    question: Question
    localImage: any
}

const PreviewQuestionAdd = (props: IPreviewQuestionAdd) => {

    return (
        <div style={{ width: "35vw", maxHeight: "70vh" }} className="dialog-content-area ma-sm pa-sm auto-scroll">
            <h1>Preview</h1>
            <div className="question-container">
                {
                    props.question.mainHeadingImage ?
                        <ImageHelper
                            onClick={() => { }}
                            defaultListening={false}
                            style={{}}
                            className="full-width"
                            image={props.question.mainHeading || props.localImage.mainHeading} /> :
                        <div className="main-heading my-md bold justify">{props.question.mainHeading}</div>
                }

                <div className="question-heading my-md">{props.question.heading}</div>
                {
                    props.question.descriptionImage ?
                        <ImageHelper
                            onClick={() => { }}
                            defaultListening={false}
                            style={{}}
                            className="full-width"
                            image={props.question.description || props.localImage.description} /> :
                        <div className={`description my-md ${props.question.descriptionBoxed ? 'description-boxed' : ''}`}>{props.question.description}</div>
                }
                {
                    props.question.type === questionType.LISTENING
                        ? props.question.listening ?
                            <div>
                                <audio id="exam-listening" src={props.question?.listening}
                                    preload="auto"
                                    controls={true}
                                ></audio>
                            </div> :
                            <div>No Listening Uploaded</div> :
                        <></>
                }

                <div className="option-container my-sm flex items-center">
                    <RadioButton
                        onImageClick={() => { }}
                        image={props.question.optionType || ''}
                        checked={props.question.answer === '1'}
                        butttonNumber={'1'}
                        name={'option'}
                        onChange={() => { }}
                        onBlur={() => { }}
                        text={
                            props.question.optionType !== optionType.LISTENING ?
                                props.question.option1 || props.localImage.option1 : ''
                        }
                        value={'1'} />

                </div>
                <div className="option-container my-sm flex items-center">
                    <RadioButton
                        onImageClick={() => { }}
                        image={props.question.optionType || ''}
                        checked={props.question.answer === '2'}
                        butttonNumber={'2'}
                        name={'option'}
                        onChange={() => { }}
                        onBlur={() => { }}
                        text={
                            props.question.optionType !== optionType.LISTENING ?
                                props.question.option2 || props.localImage.option2 : ''}
                        value={'2'} />

                </div>
                <div className="option-container my-sm flex items-center">
                    <RadioButton
                        onImageClick={() => { }}
                        image={props.question.optionType || ''}
                        checked={props.question.answer === '3'}
                        butttonNumber={'3'}
                        name={'option'}
                        onChange={() => { }}
                        onBlur={() => { }}
                        text={
                            props.question.optionType !== optionType.LISTENING ?
                                props.question.option3 || props.localImage.option3 : ''}
                        value={'3'} />

                </div>
                <div className="option-container my-sm flex items-center">
                    <RadioButton
                        onImageClick={() => { }}
                        image={props.question.optionType || ''}
                        checked={props.question.answer === '4'}
                        butttonNumber={'4'}
                        name={'option'}
                        onChange={() => { }}
                        onBlur={() => { }}
                        text={
                            props.question.optionType !== optionType.LISTENING ?
                                props.question.option4 || props.localImage.option4 : ''}
                        value={'4'} />
                </div>
            </div>
        </div>
    )
}

export default PreviewQuestionAdd
